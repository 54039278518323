import { graphql, Link } from 'gatsby';
import React from 'react';
import ProductGrid from '../components/ProductGrid';
import Seo from '../components/SEO';
import { GatsbyImage } from 'gatsby-plugin-image';

import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';

function CollectionPage({ data: { collection } }) {
  const [play] = useSound(Switch);
  if (!collection) return null;

  // const Group = collection.products;

  // const dataResult = [];
  // for (let i = 0; i < Group.length; i++) {
  //   dataResult.push(Group[i].id);
  // }

  // if (typeof window !== 'undefined') {
  //   if (window.fbq != null) {
  //     window.fbq('track', 'ViewCollection', {
  //       content_name: collection.name,
  //       content_ids: dataResult,
  //       content_type: 'product_group',
  //     });
  //   }
  // }

  return (
    <React.Fragment>
      <Seo pageTitle={collection.name} />
      <div
        className="flex flex-col justify-center flex-grow mt-16 -mb-px border lg:flex-row border-primary md:mt-20 lg:mt-12"
      >
        <div className="items-center justify-center border-b border-r lg:w-2/4 md:w-fullflex">
          <GatsbyImage
            image={
              collection.coverPhoto.localFile.childImageSharp.gatsbyImageData
            }
          />
        </div>
        <div className="flex flex-col justify-between -ml-px lg:w-2/4 md:w-full">
          <div className="flex flex-col h-full p-8 border-l lg:p-16 border-primary">
            <h1 className="pb-2 font-serif text-5xl font-bold leading-tight text-primary">
              {collection.name}
              <br /> collection
            </h1>

            <p className="max-w-lg font-sans text-sm leading-relaxed text-primary opacity-80">
              {collection.description.markdown}
            </p>
          </div>
        </div>
      </div>
      <ProductGrid products={collection.products} />
      <div className="flex flex-col items-center justify-center w-full -mt-px sm:flex-row">
        <Link onClick={play} to="/collections" className="secondary-btn">
          explore more collections
        </Link>
      </div>
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CollectionQuery($slug: String!) {
    collection: graphCmsCollection(slug: { eq: $slug }) {
      name
      slug
      description {
        markdown
      }
      products {
        id
        name
        organic
        price
        inStock
        newIn
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        remoteId
      }
      coverPhoto {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
  }
`;

export default CollectionPage;
